
import React from 'react'
import notfound from '../assets/img/not found.svg'
import Bigbutton from '../components/button/BigButton'



const notFound = (props) => {
    const {
        heading,
        message,
        click,
        btnmsg,
        link
    } = props

  return (
    <div className="col-12 mt_40 text-center">
    <img
      src={notfound}
      className="notfoundlogo"
    />
    <h2 className=" mt_30">{heading}</h2>
    <p className=" mt_10">{message}</p>
    <Bigbutton btnText={btnmsg} btnClass="black_btn mt_20" click={click} link={link}/>
    
  </div>
  )
}

export default notFound
